import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { RelationshipType, TaskStatus, FormValueAttachmentType, LinkType, OriginModuleEnum } from "infra/api/contracts";

export default class TaskLikeBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const subjects = (card.subjects && card.subjects.map(s => arxs.Api.lookups.resolveSubject(s))) || [];

    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      assignee: this.getEmployeeValue(
        card.relationships.filter((x) => x.type === RelationshipType.Assignee),
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"),
        stateProxy.getter("userRoleMap")
      ),
      owner: this.getEmployeeValue(
        card.relationships.filter((x) => x.type === RelationshipType.Owner),
        stateProxy.getter("employeeMap")
      ),
      targetDate: card.targetDate && new Date(card.targetDate),
      createdAt: card.createdAt && new Date(card.createdAt),
      executedAt: card.end && card.status === TaskStatus.Completed ? new Date(card.end) : null,
      actualDuration: card.actualDuration,
      subject_uniqueNumber: subjects.map(subject => subject.uniqueNumber).join(", "),
      subject_sort: subjects.filter(s => s.sort).map(subject => this.getCodeElementValue(
        subject.sort,
        stateProxy.getter("codeElementsById")
      )).join(", "),
      subject_kind: subjects.filter(s => s.kind).map(subject => this.getCodeElementValue(
        subject.kind,
        stateProxy.getter("codeElementsById")
      )).join(", "),
      subject_type: subjects.filter(s => s.type).map(subject => this.getCodeElementValue(
        subject.type,
        stateProxy.getter("codeElementsById")
      )).join(", "),
      subject_internalReference: subjects.map(subject => subject.internalName || subject.internalNumber).join(", "),
      supplier: this.getLookupValue(
        stateProxy.getter("supplierMap"),
        card.supplier
      ),
      contacts: this.getContactValue(
        card.contacts,
        stateProxy.getter("contactMap")
      ),
      periodical_uniqueNumber: this.getUniqueNumberOfPeriodicalOrigin(stateProxy, card.inboundLinks),
      numberOfFollowUps: this.getFormStatistics(stateProxy, card),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.kind,
      card.type,
      card.uniqueNumber,
      card.subject_uniqueNumber,
      card.title,
      card.description,
      card.responsible,
      card.assignee,
      card.owner,
      card.coResponsibles,
      card.supplier,
      card.contacts,
      card.taskType,
      card.periodical_uniqueNumber,
      card.subject_internalReference,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  getUniqueNumberOfPeriodicalOrigin = (stateProxy, links) => {
    const linkToMap = (links || []).filter(x => x.module === OriginModuleEnum.Periodical && x.type === LinkType.Origin)[0];
    if (linkToMap) {
      return this.getLookupValueWithFieldNames(stateProxy.getter("periodicalMap"), linkToMap, ["uniqueNumber"]);
    }

    return "";
  }

  getFormStatistics = (stateProxy, card) => {
    const { formValues, formDefinition } = card;
    let result = "";

    if (formDefinition) {
      const formControlMap = (formDefinition.controls || []).toDictionary(
        (x) => x.id,
        (x) => x.type
      );

      const isNonInstruction = (x) => {
        const control = x && x.control ? formControlMap[x.control] : null;
        return !control || control !== "instruction";
      };

      const items = Object.values((formValues || {}).itemsBySubject || [])
        .flatMap(Object.values).flatMap(x => x);

      const remarkCounter = items.filter(x => x.value === 'niet ok').length // de NIET OK's
        + items
          .filter(x => x.value !== 'niet ok')
          .flatMap(x => x.attachments || [])
          .filter(x => x.type && (x.type === FormValueAttachmentType.Action || x.type === FormValueAttachmentType.Comment))
          .map(x => 1).sum() // alle andere met een actie of comment 
      const answerCounter = items.filter(x => !!x.value).length;
      const questionCounter = (formDefinition.items || []).filter(isNonInstruction).length;

      result = `${remarkCounter}/${answerCounter}/${questionCounter}`;
    }

    return result;
  }
}
