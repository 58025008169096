import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router-dom';

import arxs from 'infra/arxs'
import GlobalContext from 'infra/GlobalContext';
import { Feature } from 'infra/Features';
import { ObjectDocumentType, OptinInfo, BlobImageInfo, Profile, ApiKey, CalendarPublishLink } from 'infra/api/contracts';

import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import { TextArea } from 'components/controls/TextArea';
import Avatar from 'components/controls/images/Avatar';
import Signature from 'components/controls/images/Signature'
import Button from 'components/controls/Button';
import Switch from "components/controls/Switch";
import DropDown, { DropDownItem } from 'components/controls/DropDown';
import Toaster from 'components/util/Toaster';
import { createAvatarEditor } from 'components/controls/images/AvatarEditor';
import { createUpload } from 'components/controls/upload/Upload';
import { createSignatureEditor } from 'components/controls/images/SignatureEditor';
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { createPromptPopup } from "components/shell/PromptPopup/PromptPopup";

import './UserProfile.scss'

export interface UserProfileProps {

}

interface UserProfileState {
    activePane: string,
    isDirty: boolean,
    data?: Profile,
    employeeMap?: any,
    apiKeys?: Array<ApiKey>,
    calendars?: Array<CalendarPublishLink>,
    avatarEditor?: any,
    signatureEditor?: any,
    avatar?: any | undefined,
    signature?: any | undefined,
    signaturePreviewUrl?: string,
    language?: string,
}

export default class UserProfile extends Component<UserProfileProps, UserProfileState> {

    panes = {
        account: "account",
        external: "externallogins",
        api: "api",
        calendar: "calendar",
    }

    lookups = {
        employeeMap: {},
        apiKeys: [],
        calendars: [],
    }

    state: UserProfileState = {
        activePane: this.panes.account,
        isDirty: false,
    }
    subscriptions: any;

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, (lookups: any) => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    getValue = (map: any, cardValue: any, member?: string) => {
        const id = (cardValue || {}).id;
        const ref = (map && map[id]) || {};
        return ref[member || "name"];
    }

    refresh = () => {
        arxs.ApiClient.masterdata.profile.get()
            .then(profile => this.setState({
                data: profile
            }));
    }

    saveAccount = () => {
        const { data } = this.state;

        if (data && (!data.email || (data.email && data.email.replace(/\s/g, '').length === 0))) {
            Toaster.error(arxs.t("profile.emptyemail"));
        } else {
            data && data.id && arxs.ApiClient.masterdata.profile.post(data)
                .then(() => this.setState({ isDirty: false }, () => Toaster.success(arxs.t("profile.saved"))));
        }
    }

    renderAccountPane = (context: any) => {
        const { data, employeeMap, signaturePreviewUrl } = this.state;

        if (!data) {
            return null;
        }

        const fullName = this.getValue(employeeMap, data);
        const languages = [
            { id: "en", name: "English" },
            { id: "fr", name: "Français" },
            { id: "nl", name: "Nederlands" },
        ];
        const language: DropDownItem = languages.filter(x => data && x.id === data.language)[0];
        const onChangeLanguage = (value: any) => {
            data.language = value.id;
            this.setState({ data: data, isDirty: true });
        };

        const canAddSignature = data && !data.signature;

        const signatureUrl = signaturePreviewUrl ? signaturePreviewUrl : data && data.signature && data.signature.url;

        const emailField = {
            name: 'email',
            getter: () => data && data.email,
            setter: (value: string) => {
                data.email = value;
                this.setState({ data: data, isDirty: true });
            },
            multiLine: false
        };

        const phoneField = {
            name: 'phone',
            getter: () => data && data.mobilePhone,
            setter: (value: string) => {
                data.mobilePhone = value;
                this.setState({ data: data, isDirty: true });
            },
            multiLine: false
        };

        const notificationsField = {
            name: 'emailNotifications',
            getter: () => data && data.optins && data.optins.filter((x: any) => x.type && x.type.toLowerCase() === "email").map(x => x.optin)[0] || false,
            setter: (value: boolean) => {
                data.optins.filter((x: OptinInfo) => x && x.type && x.type.toLowerCase() === "email")[0].optin = value;
                this.setState({ data: data, isDirty: true });
            }
        };

        return <div className="userprofile-details-content-account">
            <div className="userprofile-details-content-account-email">
                <div className="userprofile-details-content-account label">
                    <label>{arxs.t("profile.email")}<span> *</span></label>
                </div>
                <div className="userprofile-details-content-account value">
                    <TextArea field={emailField} key="email" className="userprofile-details-content-account-email input" />
                </div>
            </div>
            <div className="userprofile-details-content-account-notifications">
                <div className="userprofile-details-content-notifications label">
                    <label>{arxs.t("profile.notifications.email")}</label>
                </div>
                <div className="userprofile-details-content-notifications value">
                    <Switch
                        checked={notificationsField.getter()}
                        onChange={notificationsField.setter}
                        />
                </div>
            </div>
            <div className="userprofile-details-content-account-phone">
                <div className="userprofile-details-content-account label">
                    <label>{arxs.t("profile.mobile")}</label>
                </div>
                <div className="userprofile-details-content-account value">
                    <TextArea field={phoneField} key="phone" className="userprofile-details-content-account-phone input" />
                </div>
            </div>
            {arxs.Identity.profile && arxs.Identity.profile.username.toLowerCase() === 'arxssolutions' && <div className="userprofile-details-content-account-language">
                <div className="userprofile-details-content-account label">
                    <label>{arxs.t("profile.language")}</label>
                </div>
                <div className="userprofile-details-content-account value">
                    <DropDown
                          items={languages}
                          selected={language}
                          onChange={onChangeLanguage}
                    />
                </div>
            </div>}
            <div className="userprofile-details-content-account-signature">
                <div className="userprofile-details-content-signature label">
                    <label>{arxs.t("profile.signature")}</label>
                </div>
                <div className="userprofile-details-content-account-signature-value">
                    <Signature src={signatureUrl} fullName={fullName} />
                    <div className="userprofile-details-content-account-signature-controls">
                        {!canAddSignature && <div className="userprofile-details-content-signature-control delete" onClick={() => this.deleteSignature()}><i className="far fa-trash-alt"></i></div>}
                        {!canAddSignature && <div className="userprofile-details-content-signature-control edit" onClick={() => this.editSignature(context)}><i className="far fa-pencil"></i></div>}
                        {canAddSignature && <div className="userprofile-details-content-signature-control add" onClick={() => this.addSignature(context)}><i className="far fa-plus"></i></div>}
                        <div className="userprofile-details-content-signature-control upload" onClick={() => this.uploadSignature(context)}><i className="far fa-file-upload"></i></div>
                    </div>
                </div>
            </div>
            <div className="userprofile-details-content-account-actions">
                <Button key={`userprofile-save`} className="icon" onClick={() => this.saveAccount()}>{arxs.t("profile.save")}</Button>
            </div>
        </div>;
    }

    renderExternalPane = () => {
        return "external";
    }

    renderApiPane = (context: any) => {
        const { apiKeys } = this.state;

        return <div className="userprofile-details-content-api">
            {apiKeys?.filter(x => !x.isDeleted)
                .map(x => <div key={x.id} className="userprofile-details-content-api-grid">
                    <label>{x.name}</label>
                    <div>{x.key} <i className="far fa-trash-alt" onClick={() => this.removeApiKey(context, x)}></i></div>
                </div>)}
            <div className="userprofile-details-content-api-actions">
                <Button className="icon" onClick={() => this.generateApiKey(context)}>{arxs.t("profile.api.generate_api_key")}</Button>
            </div>
        </div>;
    }

    generateApiKey = (context: any) => {
        const inputPopup = createInputPopup(context,
            arxs.t("profile.api.generate_api_key_prompt"),
            (name: string) => {
                arxs.ApiClient.masterdata.profile.api.generate({ name })
                    .then(x => x.json())
                    .then(x => {
                        const promptPopup = createPromptPopup(context
                            , arxs.t("profile.api.confirm_api_key_generated")
                            , x.key);
                        context.promptPopup.show(promptPopup);
                    });
            },
            true,
            true,
            arxs.t("profile.api.generate_api_key_placeholder"),
            arxs.t("common.save"),
            arxs.t("common.cancel"),
            false);

        context.inputPopup.show(inputPopup);
    }

    removeApiKey = (context: any, x: ApiKey) => {
        const confirmation = createInputPopup(context,
            arxs.t("profile.api.confirm_api_key_removal", { name: x.name }),
            () => {
                if (x.id) {
                    arxs.ApiClient.masterdata.profile.api.remove(x.id);
                }
            });

        context.inputPopup.show(confirmation);
    }

    renderCalendarPane = (context: any) => {
        const { calendars } = this.state;

        return <div className="userprofile-details-content-api">
            {calendars?.filter(x => !x.isDeleted)
                .map(x => <div key={x.id} className="userprofile-details-content-api-grid">
                    <label>{x.name}</label>
                    <div>{x.key} <i className="far fa-trash-alt" onClick={() => this.removeCalendar(context, x)}></i></div>
                </div>)}
            <div className="userprofile-details-content-api-actions">
                <Button className="icon" onClick={() => this.generateCalendar(context)}>{arxs.t("profile.calendar.generate_api_key")}</Button>
            </div>
        </div>;
    }

    generateCalendar = (context: any) => {
        const inputPopup = createInputPopup(context,
            arxs.t("profile.calendar.generate_api_key_prompt"),
            (name: string) => {
                arxs.ApiClient.masterdata.profile.calendar.generate({ name })
                    .then(x => x.json())
                    .then(x => {
                        const promptPopup = createPromptPopup(context
                            , arxs.t("profile.calendar.confirm_api_key_generated")
                            , arxs.ApiClient.masterdata.profile.calendar.createTokenUrl(x.key));
                        context.promptPopup.show(promptPopup);
                    });
            },
            true,
            true,
            arxs.t("profile.calendar.generate_api_key_placeholder"),
            arxs.t("common.save"),
            arxs.t("common.cancel"),
            false);

        context.inputPopup.show(inputPopup);
    }

    removeCalendar = (context: any, x: ApiKey) => {
        const confirmation = createInputPopup(context,
            arxs.t("profile.calendar.confirm_api_key_removal", { name: x.name }),
            () => {
                if (x.id) {
                    arxs.ApiClient.masterdata.profile.calendar.remove(x.id);
                }
            });

        context.inputPopup.show(confirmation);
    }

    renderPane = (context: any) => {
        const { activePane } = this.state;
        switch (activePane) {
            case this.panes.account: return this.renderAccountPane(context);
            case this.panes.external: return this.renderExternalPane();
            case this.panes.api: return this.renderApiPane(context);
            case this.panes.calendar: return this.renderCalendarPane(context);
            default: return this.renderAccountPane(context);
        }
    }

    togglePane = (pane: string) => {
        const { activePane } = this.state;
        if (activePane !== pane) {
            this.setState({ activePane: pane });
        }
    }

    editAvatar = (context: any) => {
        const { data, employeeMap } = this.state;
        const userName = this.getValue(employeeMap, data, "userName");

        const onSubmitEditAvatar = (image: any) => {
            context.popup.close();

            const avatar = { previewUrl: image.previewUrl, hash: image.hash, name: image.name, contentType: image.contentType, isPreferred: true, url: image.url };

            data && this.setState({ data: { ...data, avatar: avatar }, isDirty: true });
        };

        const avatarEditor = createAvatarEditor(data && data.avatar, userName, onSubmitEditAvatar);
        this.setState({ avatarEditor: avatarEditor }, () => context.popup.show(this.state.avatarEditor));
    }

    addAvatar = (context: any) => {
        const { data } = this.state;

        const onSubmitAddAvatar = (payload: any) => {
            context.popup.close();

            if (payload.documents.length === 1) {
                const upload = payload.documents[0];
                const avatar = { url: upload.url, hash: upload.hash, name: upload.name, contentType: upload.contentType, isPreferred: true, previewUrl: upload.previewUrl };

                data && this.setState({ data: { ...data, avatar: avatar }, isDirty: true }, () => this.editAvatar(context));
            }
        };

        const upload = createUpload('Employee', null, onSubmitAddAvatar, [ObjectDocumentType.Image], [ObjectDocumentType.Image]);
        context.popup.show(upload);
    }

    editSignature = (context: any) => {
        this.addSignature(context);
    }

    addSignature = (context: any) => {
        const { data, employeeMap } = this.state;
        const userName = this.getValue(employeeMap, data, "userName");

        const onSubmitSignature = (image: BlobImageInfo) => {
            context.popup.close();

            const signature = { url: image.url, hash: image.hash, name: image.name, contentType: image.contentType };

            data && this.setState({ data: { ...data, signature: signature }, isDirty: true, signaturePreviewUrl: undefined });
        };

        const signatureEditor = createSignatureEditor(userName, onSubmitSignature);
        this.setState({ signatureEditor: signatureEditor }, () => context.popup.show(this.state.signatureEditor));
    }

    uploadSignature = (context: any) => {
        const { data } = this.state;

        const onSubmitUploadSignature = (payload: any) => {
            context.popup.close();

            if (payload.documents.length === 1) {
                const upload = payload.documents[0];
                const signature = { url: upload.url, hash: upload.hash, name: upload.name, contentType: upload.contentType, isPreferred: true, previewUrl: upload.previewUrl };

                data && this.setState({ data: { ...data, signature: signature }, isDirty: true, signaturePreviewUrl: upload.previewUrl });
            }
        };

        const upload = createUpload('Employee', null, onSubmitUploadSignature, [ObjectDocumentType.Image], [ObjectDocumentType.Image]);
        context.popup.show(upload);
    }

    deleteAvatar = () => {
        const { data } = this.state;

        data && this.setState({ data: { ...data, avatar: undefined }, isDirty: true });
    }

    deleteSignature = () => {
        const { data } = this.state;

        data && this.setState({ data: { ...data, signature: undefined }, isDirty: true });
    }

    render() {
        const { activePane, data, employeeMap, isDirty } = this.state;

        const fullName = this.getValue(employeeMap, data);

        const canAddAvatar = data && (!data.avatar || !data.avatar.url);

        const avatarUrl = data && data.avatar && data.avatar.url;

        const isApiPaneVisible = arxs.Identity.profile?.allowedActions["GlobalManagement.Write"];
        const isCalendarPaneVisible = arxs.Identity.profile?.allowedFeatures[Feature.Profile_CalendarLink];

        return <GlobalContext.Consumer>
            {
                context => <div className="userprofile">
                    <div className="userprofile-header">
                        <div className="userprofile-title">{arxs.t("profile.my_profile")}</div>
                    </div>
                    <div className="userprofile-details">
                        <div className="userprofile-details-nav">
                            <div className="userprofile-details-nav-id">
                                <div className="userprofile-details-nav-id-avatar">
                                    {data && <Fragment>
                                        {<Avatar src={avatarUrl} fullName={fullName} />}
                                        <div className="userprofile-details-nav-id-avatar-controls">
                                            {!canAddAvatar && <div className="userprofile-details-nav-id-avatar-control edit" onClick={() => this.editAvatar(context)}><i className="far fa-pencil"></i></div>}
                                            {!canAddAvatar && <div className="userprofile-details-nav-id-avatar-control delete" onClick={() => this.deleteAvatar()}><i className="far fa-trash-alt"></i></div>}
                                            {canAddAvatar && <div className="userprofile-details-nav-id-avatar-control add" onClick={() => this.addAvatar(context)}><i className="far fa-plus"></i></div>}
                                        </div>
                                    </Fragment>}
                                </div>
                                <div className="userprofile-details-nav-id-name">
                                    {`${fullName || ''}`}
                                </div>
                            </div>
                            <HorizontalSeparator />
                            <div className="userprofile-details-nav-items">
                                <div className={`userprofile-details-nav-items-item ${activePane === this.panes.account ? 'active' : ''}`}
                                    onClick={() => this.togglePane(this.panes.account)}>
                                    {arxs.t("profile.account")}
                                </div>
                                {/* <div className={`userprofile-details-nav-items-item ${activePane === this.panes.external ? 'active' : ''}`}
                                        onClick={() => this.togglePane(context, this.panes.external)}>
                                        Externe logins
                                    </div> */}
                                {isApiPaneVisible && <div className={`userprofile-details-nav-items-item ${activePane === this.panes.api ? 'active' : ''}`}
                                    onClick={() => this.togglePane(this.panes.api)}>
                                    {arxs.t("profile.api.title")}
                                </div>}
                                {isCalendarPaneVisible && <div className={`userprofile-details-nav-items-item ${activePane === this.panes.calendar ? 'active' : ''}`}
                                    onClick={() => this.togglePane(this.panes.calendar)}>
                                    {arxs.t("profile.calendar.title")}
                                </div>}
                            </div>
                        </div>
                        <div className="userprofile-details-content">
                            {this.renderPane(context)}
                        </div>
                    </div>
                    <Prompt
                        when={isDirty}
                        message={arxs.t("common.confirm_navigation")} />
                </div>
            }
        </GlobalContext.Consumer>
    }
}
