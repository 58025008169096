import * as signalR from '@microsoft/signalr';
import 'infra/ArrayExtensions';
import Lookups from 'infra/Lookups';
import ModuleSettings from 'infra/ModuleSettings';
import arxs from './arxs';
import Toaster from 'components/util/Toaster';
import { ProductType } from './Types';
import { OriginModuleEnum } from './api/contracts';
import CacheStore from './CacheStore';
import Identity from './Identity';

const _keys = {
  schema: 'ApiSchema',
  enums: 'Enums'
};

class Api {
  constructor() {
    this.signalrConnection = undefined;
    this.endpointUrl = process.env.REACT_APP_API_ENDPOINT;
    this.getToken = () => null;
    this.claims = {};
    this.headers = { 'Content-Type': 'application/json' };
    this.lookups = new Lookups(this.subscribeToEndpoint);
    this.timers = {};
  }

  initialize = () => {
    this.initializeSchema(JSON.parse(window.localStorage.getItem(_keys.schema)) || { components: { schemas: {} }, paths: {} });
    arxs.ApiClient.tryFetch(`${this.endpointUrl}/swagger/v1/swagger.json`)
      .then(async x => {
        this.initializeSchema(await x.json());
      });
    this.moduleSettings = this.buildModuleSettings();
  }

  initializeSchema = (schema) => {
    this.schema = schema;
    this.endpoints = Object.entries(schema.paths)
      .reduce((acc, x) => { acc[x[0].toLowerCase()] = x[1]; return acc; }, {});
    window.localStorage.setItem(_keys.schema, JSON.stringify(schema));
  }

  localSchemas = {
    "Files": {
      "type": "array",
      "items": {
        "$ref": "#/components/schemas/File"
      }
    },
    "WeekOfMonth": {
      "enum": [
        "First",
        "Second",
        "Third",
        "Fourth",
        "Last",
      ],
      "type": "string"
    },
    "DayOfWeek": {
      "enum": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      "type": "string"
    },
    "DayOfWeekFlag": {
      "enum": [
        "1",
        "2",
        "4",
        "8",
        "16",
        "32",
        "64",
      ],
      "type": "integer"
    },
    "MonthOfYear": {
      "enum": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      "type": "string"
    },
    "MonthOfYearFlag": {
      "enum": [
        "1",
        "2",
        "4",
        "8",
        "16",
        "32",
        "64",
        "128",
        "256",
        "512",
        "1024",
        "2048"
      ],
      "type": "integer"
    },
    "NotificationPattern": {
      "enum": [
        "Day",
        "Week",
        "Month",
      ],
      "type": "string"
    },
    "RecurrencePattern": {
      "enum": [
        "None",
        "Daily",
        "Weekly",
        "Monthly",
        "Yearly",
      ],
      "type": "string"
    },
    "Notification": {
      "type": "object",
      "properties": {
        "notificationPattern": {
          "$ref": "#/components/schemas/NotificationPattern"
        },
        "notificationCount": {
          "type": "integer",
          "format": "int32",
          "minimum": 1,
          "maximum": 9
        }
      }
    },
    "Schedule": {
      "type": "object",
      "properties": {
        "id": {
          "type": "string",
          "format": "uuid",
          "nullable": true
        },
        "isRecurring": {
          "type": "boolean",
        },
        "startDate": {
          "type": "string",
          "format": "date-time"
        },
        "endDate": {
          "type": "string",
          "format": "date-time",
          "nullable": true
        },
        "numberOfOccurrences": {
          "type": "integer",
          "format": "int32",
          "nullable": true
        },
        "interval": {
          "type": "integer",
          "format": "int32",
          "minimum": 1
        },
        "recurrencePattern": {
          "$ref": "#/components/schemas/RecurrencePattern"
        },
        "daysOfWeek": {
          "$ref": "#/components/schemas/DayOfWeekFlag"
        },
        "dayOfMonth": {
          "type": "integer",
          "format": "int32",
          "minimum": 1,
          "maximum": 31
        },
        "weekOfMonth": {
          "$ref": "#/components/schemas/WeekOfMonth"
        },
        "dayOfWeek": {
          "$ref": "#/components/schemas/DayOfWeek"
        },
        "monthOfYear": {
          "$ref": "#/components/schemas/MonthOfYear"
        },
        "notificationPattern": {
          "$ref": "#/components/schemas/NotificationPattern"
        },
        "notificationCount": {
          "type": "integer",
          "format": "int32",
          "minimum": 1
        }
      }
    },
    "GanttLine": {
      "type": "object",
      "properties": {
        "title": {
          "type": "string",
          "nullable": false
        },
        "startDate": {
          "type": "string",
          "format": "date-time"
        },
        "duration": {
          "type": "integer",
          "format": "int32"
        },
        "effort": {
          "type": "integer",
          "format": "int32"
        },
        "completionPercentage": {
          "type": "integer",
          "format": "int32",
          "maximum": 100
        },
        "module": {
          "$ref": "#/components/schemas/OriginModuleEnum"
        }
      }
    },
    "weblinkAdd": {
      "type": "object",
      "properties": {
        "weblink": {
          "type": "string",
          "nullable": false
        },
        "documentType": {
          "$ref": "#/components/schemas/ObjectDocumentType"
        }
      }
    },
    "FollowUpTask": {
      "type": "object",
      "properties": {
        "targetDate": {
          "type": "string",
          "format": "date-time"
        },
        "relationships": {
          "type": "array",
          "items": {
            "$ref": "#/components/schemas/Relationship"
          },
          "nullable": true
        }
      }
    },
    "KendoControlTesterDateFields": {
      "type": "object",
      "properties": {
        "incidentTime": {
          "type": "string",
          "format": "date-time"
        },
        "targetDate": {
          "type": "string",
          "format": "date-time"
        },
        "planFrom": {
          "type": "string",
          "format": "time"
        }
      }
    }
  }

  getSchema = (modelName) => {
    return this.localSchemas[modelName] || this.schema.components.schemas[modelName];
  }

  getReportingEndPoints = () => {
    const reportingEndpointKeys = Object.keys(this.endpoints)
      .filter(x => x.toLowerCase().endsWith("/report"))
      .filter(x => !x.toLowerCase().endsWith("/shared/report"))
      .filter(x => !x.toLowerCase().endsWith("/hazardoussubstance/report/inventory"))
      ;
    const reportingEndpointPerModule = [];

    for (const key of reportingEndpointKeys) {
      const splittedKey = key.split("/");

      let moduleKey = splittedKey[splittedKey.length - 2].toLowerCase();

      switch (moduleKey) {
        case "inspection": moduleKey = OriginModuleEnum.PeriodicControl.toLowerCase(); break;
        case "maintenance": moduleKey = OriginModuleEnum.PeriodicMaintenance.toLowerCase(); break;
        case "commissioning": moduleKey = OriginModuleEnum.Commissioning.toLowerCase(); break;
        case "decommissioning": moduleKey = OriginModuleEnum.OutOfCommissioning.toLowerCase(); break;
        case "safetyinstructioncard": moduleKey = OriginModuleEnum.SafetyInstructionCard.toLowerCase(); break;
        case "instructioncard": moduleKey = OriginModuleEnum.InstructionCard.toLowerCase(); break;
        case "recommendation": moduleKey = OriginModuleEnum.Consultancy.toLowerCase(); break;
        case "incident": moduleKey = OriginModuleEnum.IncidentManagement.toLowerCase(); break;
        case "taskrequest": moduleKey = OriginModuleEnum.NotificationDefect.toLowerCase(); break;
        case "multiyearplan": moduleKey = OriginModuleEnum.GlobalPreventionPlan.toLowerCase(); break;
        case "documentmanagement": moduleKey = OriginModuleEnum.Document.toLowerCase(); break;
        default: break;
      }

      let module = arxs.moduleMetadataRegistry.supportedModules.find(mod => mod.toLowerCase() === moduleKey);

      reportingEndpointPerModule.push({ module: module, endpoint: { ...this.endpoints[key], url: key } });
    }

    return reportingEndpointPerModule;

  }

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  logOff = () => {
    this.clear();

    if (this.signalrConnection) {
      this.signalrConnection.stop();
      this.signalrConnection = undefined;
    }
  }

  clear = () => {
    this.getToken = () => null;
    this.claims = {};
  }

  trySetToken = (getToken) => {
    this.getToken = getToken;

    if (!this.getToken()) {
      arxs.logger.info('Authentication token expired...');
      this.clear();
      return false;
    }
  }

  // Can be optimized to scan the schema for an endpoint that ends in /refs that returns {ResourceName}Refs as item schema
  getResource = (name) => {
    switch (name.toLowerCase()) {
      case "legalstructure": return arxs.ApiClient.masterdata.legalStructure;
      case "branch": return arxs.ApiClient.masterdata.branch;
      case "building": return arxs.ApiClient.assets.building;
      case "location": return arxs.ApiClient.assets.location;
      case "equipment": return arxs.ApiClient.assets.equipment;
      case "labourmeans": return arxs.ApiClient.assets.labourmeans;
      case "protectionequipment": return arxs.ApiClient.assets.protectionEquipment;
      case "hazardoussubstance": return arxs.ApiClient.assets.hazardousSubstance;
      case "combinedinstallation": return arxs.ApiClient.assets.combinedInstallation;
      case "taskrequest": return arxs.ApiClient.facilitymanagement.taskRequest;
      case "task": return arxs.ApiClient.facilitymanagement.task;
      case "messages": return arxs.ApiClient.messages();
      case "supplier": return arxs.ApiClient.masterdata.supplier;
      case "nacebell": return { getRefs: () => Promise.resolve([]) };
      case "paritaircomite": return { getRefs: () => Promise.resolve([]) };
      case "city": return { getRefs: () => Promise.resolve([]) };
      case "zipCode": return { getRefs: () => Promise.resolve([]) };
      case "employee": return arxs.ApiClient.masterdata.employee;
      case "trust": return arxs.ApiClient.masterdata.trust;
      case "activityentry": return arxs.ApiClient.facilitymanagement.activityEntry;
      case "contact": return arxs.ApiClient.masterdata.contact;
      case "incidentmanagement":
      case "incident": return arxs.ApiClient.safety.incident;
      case "documentmanagement": return arxs.ApiClient.shared.documentManagement;
      case "project": return arxs.ApiClient.facilitymanagement.project;
      case "reportdefinition": return arxs.ApiClient.shared.reportDefinition;
      case "intangibleasset": return arxs.ApiClient.assets.intangibleAsset;
      case "form": return arxs.ApiClient.facilitymanagement.form;
      case "periodicmaintenance":
      case "maintenance": return arxs.ApiClient.facilitymanagement.maintenance;
      case "periodiccontrol":
      case "inspection": return arxs.ApiClient.facilitymanagement.inspection;
      case "periodical": return arxs.ApiClient.facilitymanagement.periodical;
      case "multiyearplan": return arxs.ApiClient.safety.multiYearPlan;
      case "instructioncard": return arxs.ApiClient.safety.instructionCard;
      case "safetyinstructioncard": return arxs.ApiClient.safety.safetyInstructionCard;
      case "requirements": return { getRefs: () => Promise.resolve([]) };
      case "commissioning": return arxs.ApiClient.safety.commissioning;
      case "riskanalysis": return arxs.ApiClient.safety.riskAnalysis;
      case "consultancy":
      case "recommendation" : return arxs.ApiClient.safety.recommendation;
      case "decommissioning" : return arxs.ApiClient.safety.decommissioning;
      default: throw new Error(`Unknown resource ${name}`);
    }
  }

  buildModuleSettings = () => {
    const baseUrl = `${this.endpointUrl}/api/shared/modulesetting`;
    const group = "arxs.frontend";

    return new ModuleSettings({
      get: () => arxs.ApiClient.tryFetchJson(`${baseUrl}/${group}`, {
        "method": "GET",
        "headers": arxs.ApiClient.getHeaders(),
      }),
      post: (items) => arxs.ApiClient.tryFetchJson(`${baseUrl}`, {
        "method": "POST",
        "headers": arxs.ApiClient.getHeaders(),
        "body": JSON.stringify({ group, items: items })
      }),
    });
  }

  session = (() => {
    return {
      get: () => arxs.ApiClient.tryFetchJson(`${this.endpointUrl}/api/shared/session`),
    };
  })()

  translations = (() => {
    return {
      get: (language, productType) => arxs.ApiClient.tryFetchJson(`${this.endpointUrl}/api/shared/translations/${language}/${ProductType[productType]}`),
    };
  })()

  endpointSubscriptions = {}

  subscribeToEndpoint = (endpoint, handler, requiredAction) => {
    if (requiredAction) {
      if (!arxs.isActionAllowed(requiredAction)) {
        return {
          update: () => { },
          dispose: () => { }
        };
      }
    }

    endpoint = endpoint.toLowerCase();

    const baseUrl = `${this.endpointUrl}${endpoint}`;
    const cacheKey = `${Identity.profile.id} => ${baseUrl}`;

    let onSubscriptionInitialized;
    let subscription = this.endpointSubscriptions[endpoint];
    if (!subscription) {
      const refresh = (explicitHandler) => {
        const since = subscription.since;

        let headers = arxs.ApiClient.getHeaders();

        if (since) {
          const sinceCompensatedForClockDrift = arxs.dateTime.addDays(new Date(since), -1 / 48).toISOString();
          headers = {
            ...headers,
            "IfModifiedSince": sinceCompensatedForClockDrift,
          };
        }

        arxs.ApiClient.tryFetch(baseUrl, { headers })
          .then((response) => {
            // console.timeLog(baseUrl);
            let onDataLoaded;
            if (response.status === 304) {
              onDataLoaded = new Promise((resolve) => resolve(subscription.response));
            } else {
              onDataLoaded = response.json()
                .then((payload) => {
                  const previousResponse = subscription.response;
                  let newResponse = payload;

                  if (previousResponse) {
                    if (Array.isArray(newResponse)) {
                      for (let i = 0; i < newResponse.length; i++) {
                        const item = newResponse[i];
                        const idx = previousResponse.findIndex(x => x.id === item.id);
                        if (idx > -1) {
                          previousResponse[idx] = item;
                        } else {
                          previousResponse.push(item);
                        }
                      }

                      newResponse = previousResponse;
                    }
                  }

                  return newResponse;
                })
                .catch((error) => {
                  const errorMessage = "" + error;
                  arxs.logger.warn("Fetch {url} failed: {error}", baseUrl, errorMessage);
                  arxs.ApiClient.toastFetchError();
                  return new Promise((resolve, reject) => { });
                });
            }

            return onDataLoaded
              .then(data => {
                subscription.response = data;

                // Certain endpoints have not yet been whitelisted to support If-Modified-Since -> 304
                // These do not return a Last-Modified header
                // We do however want to limit our querying even in those cases
                // So we generate a last-modified locally that is current time - 30 minutes
                subscription.since = response.headers.get("Last-Modified")
                  || arxs.dateTime.addDays(new Date(), -1 / 48).toISOString();

                // Image SAS tokens expire after 7 days, we randomly want to expire our caches between 2 and 6 days
                // First of all we don't want to align cache expiration with the image SAS token expiration
                //   since if all the caches expire at the same time, the user is faced with us needing to refresh ALL endpoints at once
                //   generating high load on the server, and a bad user-experience
                //   As such we randomly distribute expirations between 2 and 6 days so individual endpoints are expire randomly throughout
                // Secondly why not expire between 3 and 7 days as to align with the 7 day SAS token expiration?
                //   If we did that, a user could have a scenario where he starts working on the 7th day and somewhere within that 7th day the SAS tokens expire
                //   By offsetting by one day we minimize this from happening.
                subscription.expires = subscription.expires
                  || arxs.dateTime.addDays(new Date(), 2 + Math.random() * 4).toISOString();

                try {
                  CacheStore.set(cacheKey, {
                    response: subscription.response,
                    since: subscription.since,
                    expires: subscription.expires,
                  });
                } catch { }
                // console.timeEnd(baseUrl);
                return data;
              });
          }).then(response => {
            if (explicitHandler) {
              return explicitHandler(response);
            } else {
              for (const handler of subscription.handlers) {
                handler(response);
              }
            }
          });
      };

      subscription = {
        handlers: [],
        refresh
      };

      this.endpointSubscriptions[endpoint] = subscription;

      // console.time(baseUrl);

      onSubscriptionInitialized = CacheStore.get(cacheKey)
        .then(cacheItem => {
          if (cacheItem) {
            // console.timeLog(baseUrl);
            const expiresAt = new Date(cacheItem.expires);
            if (!isNaN(expiresAt) && expiresAt > new Date()) {
              subscription.response = cacheItem.response;
              subscription.since = cacheItem.since;
              subscription.expires = cacheItem.expires;
            }
          }
          return subscription;
        });
    } else {
      onSubscriptionInitialized = new Promise((resolve) => resolve(subscription));
    }

    subscription.handlers.push(handler);

    const update = () => subscription.refresh(handler);

    const dispose = () => {
      const index = subscription.handlers.indexOf(handler);
      if (index > -1) {
        subscription.handlers.splice(index, 1);
      }
    };

    onSubscriptionInitialized
      .then(subscription => {
        if (subscription && subscription.response) {
          handler(subscription.response);
        }
        return subscription;
      })
      .then(subscription => {
        const tokens = endpoint.split("/");
        const resource = tokens[tokens.length - 1];
        const cachedLastModifiedRaw = subscription.since;
        const serverLastModifiedRaw = Identity.profile.lastModifiedMap[resource];
        const cachedLastModified = new Date(cachedLastModifiedRaw);
        const serverLastModified = new Date(serverLastModifiedRaw);
        const shouldUpdate = !cachedLastModifiedRaw || !serverLastModifiedRaw || cachedLastModified < serverLastModified;
        if (shouldUpdate) {
          update();
        } else {
          // console.log("Skipped resource " + resource + " since " + subscription.since + " < " + serverLastModified);
        }
      });

    return {
      update,
      dispose
    };
  }

  invalidateEndpoint = (sanitizedKey) => {
    const keysToRefresh = Object.keys(this.endpointSubscriptions).filter(key => key.endsWith(sanitizedKey));

    return Promise.all(keysToRefresh.map(key => {
      const subscription = this.endpointSubscriptions[key];
      if (subscription) {
        return subscription.refresh();
      }
      return new Promise((resolve, reject) => { resolve(); })
    }));
  }

  initializeSignalr = () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.endpointUrl}/hubs/messagingHub?tenantId=${arxs.Identity.tenant}`, { accessTokenFactory: () => this.getToken() })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Trace)
      .build();

    const delay = 200;

    connection.on("ReceiveCacheInvalidation",
      message => {
        if (!message) {
          return;
        }

        for (const entry of message) {
          let url = '';

          const segments = entry.split(':');
          if (segments.length === 1) {
            url = entry;
          } else if (segments.length > 1) {
            url = segments[1];
          } else if (segments.length > 2) {
            url = segments[2];
          }

          if (url) {
            // In order to support urls like /api/resource?since=date
            const sanitizedKey = url.split("?")[0];
            
            if (this.timers[sanitizedKey]) {
              window.clearTimeout(this.timers[sanitizedKey]);
            }

            this.timers[sanitizedKey] = window.setTimeout(() => {
              this.invalidateEndpoint(sanitizedKey)
                .then(() => {
                  delete this.timers[sanitizedKey];
                });
            }, delay);
          }
        }
      });

    connection.on("ReceiveEditedDocumentSavedNotification",
      message => {
        Toaster.success(`${message[0]}${arxs.t("controls.document.editor.saved")}`);
      }
    );

    connection.on("ReceiveSecurityContextInvalidation",
      message => {
        Toaster.notify(arxs.t("security.security_context_invalidated"), false);
      }
    );

    connection.start().catch(function (err) {
      return console.error(err.toString());
    });

    this.signalrConnection = connection;
  }
}
export default new Api();
